<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Brand logo-->
      <b-link class="brand-logo">
        <!-- <vuexy-logo /> -->
        <span class="brand-logo">
          <h3 class="text-primary font-weight-bold">ECloudAccess</h3>
          <!-- <b-img :src="appLogoImage" width="180px" alt="logo" /> -->
        </span>
        <!-- <h2 class="brand-text text-primary ml-1">
            Secusy
          </h2> -->
      </b-link>
      <!-- /Brand logo-->

      <!-- Left Text-->
      <b-col lg="8" class="d-none d-lg-flex align-items-center p-5">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5"
        >
          <b-img fluid :src="imgUrl" alt="Login V2" />
        </div>
      </b-col>
      <!-- /Left Text-->

      <!-- Login-->
      <b-col lg="4" class="d-flex align-items-center auth-bg px-2 p-lg-5">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <b-card-title class="mb-3 font-weight-bold text-center text-primary" title-tag="h3">
            Login to ECloudAccess!
          </b-card-title>
          <div class="d-flex justify-content-center">
            <!-- submit buttons -->
            <a
              class="btn btn-primary btn-md login-button mt-2 mx-auto"
              v-bind:href="login_url"
              block
              v-if="!loading"
            >
              Login with office 365
            </a>
          <a
              class="btn btn-primary btn-md login-button mt-2 mx-auto"
              block
              v-else
              disabled
            >
              Please Wait ...
            </a>
          </div>
        </b-col>
      </b-col>
      <!-- /Login-->
    </b-row>
  </div>
</template>
  
  <script>
/* eslint-disable global-require */
/* eslint-disable */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import VuexyLogo from "@core/layouts/components/Logo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BAlert,
  VBTooltip,
} from "bootstrap-vue";
import useJwt from "@/auth/jwt/useJwt";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import { getHomeRouteForLoggedInUser } from "@/auth/utils";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { $themeConfig } from "@themeConfig";

export default {
  directives: {
    "b-tooltip": VBTooltip,
  },
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    BAlert,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      login_url: "",
      code: null,
      applications: [],
      loading: false,
      sideImg: require("@/assets/images/pages/login-v2.svg"),
    };
  },
  computed: {
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        this.sideImg = require("@/assets/images/pages/login-v2-dark.svg");
        return this.sideImg;
      }
      return this.sideImg;
    },
  },
  created() {
    this.load();
  },
  methods: {
    load: function () {
      let urlParams = new URLSearchParams(window.location.search);
      let code = urlParams.get("code");
      // console.log(code, "pppppppppp");
      // console.log(this.login_url, "<<<<< login url>>>");

      if (code == null) {
        const options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url: process.env.VUE_APP_BASEURL + "login",
        };
        this.$http(options).then((res) => {
          (this.login_url = res.data.login_url),
            console.log(res.data, "<<< login url >>>");
        });
      } else {
        this.loading = true
        // console.log(code);
        const options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url: process.env.VUE_APP_BASEURL + "save?code=" + code,
        };
        this.$http(options).then((res) => {
          // console.log(res.data, "checking permissionnnnnnnnnnnnn")
          let data = {
            token: res.data.token,
            userData: res.data.userData,
            permissions: res.data.permissions,
          };
          this.$store
            .dispatch("app/login", data, { root: true })
            .then(() =>
              // this.$router.replace('login')
              window.history.replaceState({}, document.title, window.location.origin + '/#'),
              this.$router.push({ name: "home" })
              // this.$router.push('/')
            )
            .catch((err) => {
              window.history.replaceState({}, document.title, window.location.origin + '/#')
              this.loading = false
              console.log(err, "error");
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Unable to log in with provided credentialss.",
                  icon: "EditIcon",
                  variant: "error",
                },
              });
            });
        });
      }
    },
  },
  setup() {
    const { appName, appLogoImage } = $themeConfig.app;
    return {
      // App Name
      appName,
      appLogoImage,
    };
  },
};
</script>
  
  <style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
</style>
  